import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>ブラウザの幅：{window.outerWidth}</div>
        <div>ブラウザの高さ：{window.outerHeight}</div>
        <div>表示領域の幅：{window.innerWidth}</div>
        <div>表示領域の高さ：{window.innerHeight}</div>
      </header>
    </div>
  );
}

export default App;
